import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

import PaymentModal from "../components/PaymentModal";
// import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

function ContactUs() {
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (window.location.search.includes("success=true")) {
      setSuccess(true);
    }
  }, []);

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleOpenModal() {
    setShowModal(true);
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sunrise Speech and Swallowing Therapy | Contact Us</title>
        <link
          rel="canonical"
          href="https://sunrisespeechandswallowing.com/contact-us"
        />
        <meta
          name="description"
          content="Sunrise Speech &amp; Swallowing Therapy"
        />
        <meta
          name="keywords"
          content="Speech therapy, Swallowing therapy, Asheville, NC"
        />
        <meta
          name="author"
          content="Need help or assistance? Please feel free to fill out the form
                below and we'll get back to you as soon as possible!"
        />
      </Helmet>
      <main>
        <Nav openModal={handleOpenModal} />
        {showModal && <PaymentModal closeModal={handleCloseModal} />}

        <section className="bg-white border-b py-8 mt-12 mx-auto max-w-6xl p-4">
          <h1 className="w-full my-2 mt-12 text-5xl font-bold leading-tight text-center text-sunrise-blue">
            Contact Us
          </h1>

          <div className="container mt-5 flex mb-10 border-b border-sunrise-blue pb-10 p-2 md:flex-row flex-col">
            <div className="md:w-5/12 p-4 pt-0">
              <h2 className="text-center pb-3 pt-0 mr-3 ml-3 text-3xl md:pr-3 md:pl-3 border-b border-sunrise-blue mb-4 text-sunrise-blue">
                Sunrise Speech &amp; Swallowing Therapy
              </h2>
              Contact us at: <br />
              <address className="">
                <div className="mb-3">966 Tunnel Road Asheville, NC 28805</div>
                <div>PHONE:</div>
                <a
                  className="inline-block text-sunrise-blue no-underline hover:text-sunrise-orange-1 hover:underline mb-3"
                  to="tel:+8282201836"
                >
                  (828) 220-1836
                </a>
                <br />
                FAX: <br />
                <a
                  className="inline-block text-sunrise-blue no-underline hover:text-sunrise-orange-1 hover:underline mb-3"
                  to="tel:+8285814096"
                >
                  (828) 581-4096
                </a>
              </address>
              <div>Hours of operation:</div>
              <table className="text-sm">
                <tr>
                  <th className="text-left pr-8">Sunday:</th>
                  <td>Closed</td>
                </tr>
                <tr>
                  <th className="text-left pr-8">Monday:</th>
                  <td>9am - 5pm</td>
                </tr>
                <tr>
                  <th className="text-left pr-8">Tuesday:</th>
                  <td>9am - 5pm</td>
                </tr>
                <tr>
                  <th className="text-left pr-8">Wednesday:</th>
                  <td>9am - 5pm</td>
                </tr>
                <tr>
                  <th className="text-left pr-8">Thursday:</th>
                  <td>9am - 5pm</td>
                </tr>
                <tr>
                  <th className="text-left pr-8">Friday:</th>
                  <td>9am - 5pm</td>
                </tr>
                <tr>
                  <th className="text-left pr-8">Saturday:</th>
                  <td>9am - 12pm</td>
                </tr>
              </table>
            </div>
            <div className="md:w-7/12  mr-4 ml-4 md:pl-6 md:m-0">
              <iframe
                width="100%"
                height="475"
                frameborder="0"
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJCxdRcIvzWYgReq_96r0xhAY&key=AIzaSyBeaOGMSqJtZH3lxrmkHFJPwda3clZQ21s"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="container max-w-l mx-auto p-2">
            <div className="mb-10 flex justify-center ">
              <p className="justify-center w-5/6">
                Need help or assistance? Please feel free to fill out the form
                below and we'll get back to you as soon as possible!
              </p>
            </div>
            {success && (
              <h2 style={{ color: "green", textAlign: "center" }}>
                Thank you for contacting us, we will respond as soon as
                possible!{" "}
              </h2>
            )}
            <form
              name="contact"
              method="POST"
              action="/contact-us/?success=true"
              netlify-honeypot="bot-field"
              data-netlify="true"
              className="md:border-solid md:border-2 border-surise-blue md:shadow-lg p-8 pt-6 max-w-lg sm:mx-auto m-2"
            >
              <div className="max-w-lg mx-auto">
                <p class="hidden">
                  <label>
                    Don’t fill this out if you’re human:{" "}
                    <input name="bot-field" />
                  </label>
                </p>
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="p-1 pl-2 text-sm mt-1 block w-full rounded-md border-sunrise-orange-1 shadow-sm focus:border-indigo-300 border mb-5"
                  placeholder=""
                />
                <label htmlFor="email">Email Address</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="p-1 pl-2 text-sm mt-1 block w-full rounded-md border-sunrise-orange-1 shadow-sm focus:border-indigo-300 border"
                  placeholder=""
                />
                <label htmlFor="email" className="w-full mt-5 block">
                  How can we help?
                </label>
                {/* <br /> */}
                <textarea
                  resize="none"
                  id="email"
                  name="story"
                  className="p-4 text-sm mt-1 block w-full border resize-none h-56 border-solid border-sunrise-orange-1 rounded-md"
                ></textarea>
              </div>
              <div className="pt-6 flex justify-center">
                <button
                  className="bg-sunrise-orange-1 rounded-lg pt-2 pb-2 pr-6 pl-6 border-0 text-white shadow-md"
                  role="button"
                  type="submit"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </section>

        <Footer openModal={handleOpenModal} />
      </main>
    </>
  );
}

export default ContactUs;
